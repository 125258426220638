import ReviewsBGImage from '../../assets/img/ReviewsBGImage.png';
import { TestimonialCarousel } from './carousels';
import { GenericAnimation } from "../common/animations";
import { Suspense } from 'react';

export function Testimonials() {
  return (
    <Suspense>
    <section className="min-h-screen w-screen flex" style={{ backgroundImage: `url(${ReviewsBGImage})`, backgroundSize: 'cover' }}>
      <div className="pt-20">
        <GenericAnimation>
        <h1 className="text-3xl md:text-5xl text-center mb-40 text-white">Every Guest has a story</h1>
        <div className="flex justify-center">
          <TestimonialCarousel />
        </div>
        </GenericAnimation>
      </div>
    </section>
    </Suspense>
  )
}
