import { useState } from 'react';
import { GoThreeBars } from 'react-icons/go';
import { IoMdClose } from 'react-icons/io';
import SwixLogoGold from '../../assets/img/SwixLogoGold.png';
import SwixLogoWhite from '../../assets/img/SwixLogoWhite.png';
import {
  ABOUT_US_URL,
  ALL_LISTINGS_URL,
  BOOKING_ENGINE_BASE_URL,
  CONTACT_US_URL
} from '../../urls';

export default function Navbar() {
  const [expanded, setExpanded] = useState(false);
  return (
    <nav className="flex items-center justify-between flex-wrap bg-[#766457] md:bg-[#FBF6F2] border-b border-slate-300 px-12 py-6 text-white md:text-gray-600">
      <div className="w-1/3 flex items-center flex-shrink-0 mr-6">
      <a href={BOOKING_ENGINE_BASE_URL} className="hidden md:block">
        <img
          src={SwixLogoGold}
          width={180}
          height={75}
          alt="Logo"/>
        </a>
        <a href={BOOKING_ENGINE_BASE_URL} className="md:hidden">
        <img
          src={SwixLogoWhite}
          width={180}
          height={75}
          alt="Logo"/>
        </a>
      </div>
      <div className="block lg:hidden">
        <button className="flex items-center px-3 py-2 rounded-2xl" onClick={() => setExpanded(!expanded)}>
          {expanded ? <IoMdClose size={36} /> : <GoThreeBars size={36} /> }
        </button>
      </div>
      <div className={"w-full flex-grow lg:flex lg:flex-row lg:items-center md:w-auto lg:justify-end " + ( expanded ? "block" : "hidden")}>
        <div className="text-lg mt-4 md:mt-2 block lg:flex md:flex-row">
          <div className="mr-6">
            <a className="mb-6 text-xl font-bold" href={ALL_LISTINGS_URL}>Our Portfolio</a>
          </div>
          <div className="mr-6">
            <a className="mb-6 text-xl font-bold" href={ABOUT_US_URL}>About Swix</a>
          </div>
          <div className="mr-6">
            <a className="mb-6 text-xl font-bold" href={CONTACT_US_URL}>Contact Us</a>
          </div>
        </div>
      </div>
    </nav>
  )
}
