import { GenericAnimation } from "../animations";

export default function OutlineCard({ children } : { children:any }) {

  return (
    <GenericAnimation>
    <div className="bg-white rounded-[30px] md:rounded-[60px] border border-black p-2 flex justify-center">
      {children}
    </div>
    </GenericAnimation>
  )
}
