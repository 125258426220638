import { Routes, Route } from 'react-router-dom';
import { Landing } from './components/landing';
import { Privacy } from './components/privacy';
import { Disclaimer } from './components/disclaimer';
import { Terms } from './components/terms';

const Main = () => {
return (
  <Routes>
    <Route path='/' element={<Landing/>} />
    <Route path='/privacy-policy' element={<Privacy/>} />
    <Route path='/disclaimer' element={<Disclaimer/>} />
    <Route path='/terms' element={<Terms/>} />
  </Routes>
);
}
export default Main;
