import { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const squareVariants = {
  visible: { y: 0, opacity: 1,  transition: { duration: 0.3 } },
  hidden: { y: 30, opacity: 0 }
};

export function GenericAnimation({ children } : { children:any }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      className="square"
      transition={{ duration: 0.3, delay: 0}}
      animate={controls}
      variants={squareVariants}
    >
      {children}
    </motion.div>
  )
}
