import GlobalMap from '../../assets/img/GlobalMap.png';
import { GenericAnimation } from "../common/animations";
import { Suspense } from 'react';

export function Map() {
  return (
    <Suspense>
    <section className="hidden md:block min-h-screen w-screen bg-[#1e4d6d] px-8" style={{ backgroundImage: `url(${GlobalMap})`, backgroundSize: '75vw', backgroundRepeat: 'no-repeat', backgroundPosition: "center"}}>
      <GenericAnimation>
      <h2 className="text-4xl md:text-6xl text-center text-white pt-8">Swix is expanding globally</h2>
      </GenericAnimation>
    </section>
    </Suspense>
  )
}
