import { Navbar, Footer } from '../layout';
import {
  Header,
  Introduction,
  Map,
  Booking,
  Club,
  Portfolio,
  Testimonials
} from './';

export function Landing() {
  return (
    <main>
      <Navbar />
      <Header />
      <Introduction />
      <Map />
      <Booking />
      <Club />
      <Portfolio />
      <Testimonials />
      <Footer/>
    </main>
  )
}
