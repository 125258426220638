import { useState, createRef, Suspense } from 'react';
import './Slider.css';
import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs';

const data = [
  {
    text: '“Excellent spot. My new home in London. I must say that this the best apartment I have stayed in London. First, the amount of space you have is not typical of London apartments. The media room is also amazing. Also perfect if you have meetings in central and want to dine later in central.”',
    author: "Olumide S.",
    location: "London"
  },
  {
    text: '“Absolutely fabulous! best self-catering accommodation we’ve used and we’ve used plenty over the years was very well decorated, comfortable, lots of information and helpful hosts lots of little extras like fresh milk etc included.”',
    author: "Liam C.",
    location: "Bordeaux"
  },
  {
    text: '“Qualità prezzo ottima pulizia ottima gestore molto professionale e disponibile.bellissima esperienza.”',
    author: "Giulio B.",
    location: "Milan"
  },
  {
    text: '“A beautifully appointed apartment in an excellent location. A warm welcome was afforded to us by our hosts on arrival in the immaculately clean apartment, full of lovely little personal touches.”',
    author: "Fred R.",
    location: "Dublin"
  },
  {
    text: '“Heel fijn dat er een mobiele airco was, dat was heel fijn bij 30 graden! Goede buurt. Prachtig, nieuw appartement. Bedbank sliep niet fantastisch.”',
    author: "Elisabeth H.",
    location: "Amsterdam"
  },
  {
    text: '“Amazing flat, great location just across the road of Hide Park. Clean, comfortable and organised flat - missed a few cooking stuff (we were a big group cooking dinner/lunch) but not major.”',
    author: "Marcela S.",
    location: "Rio De Janeiro"
  },
  {
    text: '“Gorgeous appartement! Extra clean! Secure area! And most amazing host! Everything was perfect and Im a picky person.”',
    author: "Colette N.",
    location: "Paris"
  },
  {
    text: '“Stunning 3 bedroom apartment with a superbly equipped kitchen and stunning 5 metre high stud grand living room with lovely ornate ceilings and terrace situated just 100 metres from Hyde park!”',
    author: "Wissam S.",
    location: "Beirut"
  },
  {
    text: '“The property is immaculate. You simply cannot compare the space with a hotel room. The floor space is big enough for a family. This property has everything you need for a comfortable break with your family.”',
    author: "Muhammad S.",
    location: "Dubai"
  },
];

const colors = [ "[#f2efeb]" , "[#937b6e]", "[#e1dacc]" ]
const textColors = [ "black", "white", "black"]

export function TestimonialCarousel() {
  const [currentImage, setCurrentImage] = useState(0);

  const refs : any = data.reduce<any>((acc, val, i) => {
    acc[i] = createRef();
    return acc;
  }, {});

  const scrollToImage = (i: number, mobile: boolean) => {
    console.log(mobile)
    setCurrentImage(i);
    refs[i].current.scrollIntoView({
      behavior: mobile ? 'auto' : 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  const totalImages = data.length;

  const nextImage = (mobile : boolean) => {
    console.log(mobile)
    if (currentImage >= totalImages - 1) {
      scrollToImage(0, mobile);
    } else {
      scrollToImage(currentImage + 1, mobile);
    }
  };

  const previousImage = (mobile : boolean) => {
    if (currentImage === 0) {
      scrollToImage(totalImages - 1, mobile);
    } else {
      scrollToImage(currentImage - 1, mobile);
    }
  };

  // const [count, setCount] = useState(0);
  // useEffect(() => {
  //  const timer = setTimeout(() => {
  //    setCount(count+1);
  //    nextImage();
  //  }, 3e3)
  //  return () => clearTimeout(timer)
  // }, [count])

  const arrowStyle =
    'absolute text-white text-2xl z-10 bg-[#766457] h-10 w-10 rounded-full opacity-75 items-center justify-center';

  const sliderControl = (isLeft: boolean) => (
    <>
    <button
      type="button"
      onClick={isLeft ? () =>  previousImage(true) : () => nextImage(true)}
      className={`${arrowStyle} ${isLeft ? 'left-2' : 'right-2'} flex md:hidden`}
      style={{ top: '40%' }}
    >
      <span role="" aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
        {isLeft ? '◀' : '▶'}
      </span>
    </button>
    <button
      type="button"
      onClick={isLeft ? () =>  previousImage(false) : () => nextImage(false)}
      className={`${arrowStyle} ${isLeft ? 'left-2' : 'right-2'} hidden md:flex`}
      style={{ top: '40%' }}
    >
      <span aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
        {isLeft ?
        <BsFillCaretLeftFill size={30}/>:
        <BsFillCaretRightFill size={30}/>}
      </span>
    </button>
    </>
  );

  return (
    <Suspense>
    <div className="py-8 px-2 md:px-8 flex justify-center w-screen items-center">
      <div className="relative w-screen">
        <div className="carousel ml-[calc(2.5%+2rem)] md:ml-[2vw] xl:ml-[3vw] 2xl:ml-[3vw]">
          {sliderControl(true)}
          {data.map((el, i) => (
            <div className={"mx-2 w-full md:w-1/2 xl:w-[calc(30%+2rem)] 2xl:w-[calc(30.5%+2rem)] flex-shrink-0"} key={el.author[0] + i.toString()} ref={refs[i]}>
              <div className={"bg-" + colors[i%3] + " rounded-br-[60px] p-2 flex justify-center h-96 xl:h-80"}>
                <div className={"w-3/4 flex flex-col justify-around py-4 text-" + textColors[i%3]}>
                  <p className="font-light">
                    {el.text}
                  </p>
                  <div>
                    <h5 className="font-bold text-lg mt-12">{el.author}</h5>
                    <p>{el.location}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {sliderControl(false)}
        </div>
      </div>
    </div>
    </Suspense>
  );
};
