import React, { useEffect } from 'react';
import { BOOKING_ENGINE_BASE_URL } from '../../urls';

const SearchBar: React.FC<{ color?: string }> = ({color}) => {

    useEffect(() => {
		const script = document.createElement('script');
		script.src = "https://d2q3n06xhbi0am.cloudfront.net/widget.js?1640277196";
		script.async = true;
		
		document.body.appendChild(script);
	
		let props = {
			baseUrl: BOOKING_ENGINE_BASE_URL,
			showLocation: true,
			color: color ?? '#8c620e',
			rounded: true,
			openInNewTab: false, // or false  
			font: 'Arial',
		}

		script.onload = () => {
			window.searchBar(props);
		};

		return () => {
			document.body.removeChild(script);
		};
	}, [color]);

	return <div id="hostaway-booking-widget" />;
};

export default SearchBar;