import { useState, createRef, Suspense } from 'react';
import './Slider.css';
import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs';
import { One, Two, Three, Four, Five, Six, Seven, Eight,
        Nine, Ten, Eleven, Twelve, Thirteen } from '../../../assets/img/Carousel';
const images = [ One, Two, Three, Four, Five, Six, Ten, Seven, Eleven, Eight,
        Thirteen, Nine, Twelve ];

export function LaptopCarousel() {
  const [currentImage, setCurrentImage] = useState(0);

  const refs : any = images.reduce<any>((acc, val, i) => {
    acc[i] = createRef();
    return acc;
  }, {});

  const scrollToImage = (i: number) => {
    setCurrentImage(i);
    refs[i].current.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'start',
    });
  };

  const totalImages = images.length;

  const nextImage = () => {
    if (currentImage >= totalImages - 1) {
      scrollToImage(0);
    } else {
      scrollToImage(currentImage + 1);
    }
  };

  const previousImage = () => {
    if (currentImage === 0) {
      scrollToImage(totalImages - 1);
    } else {
      scrollToImage(currentImage - 1);
    }
  };

  // const [count, setCount] = useState(0);
  // useEffect(() => {
  //  const timer = setTimeout(() => {
  //    setCount(count+1);
  //    nextImage();
  //  }, 3e3)
  //  return () => clearTimeout(timer)
  // }, [count])

  const arrowStyle =
    'absolute text-white text-2xl z-10 bg-[#766457] h-10 w-10 rounded-full opacity-75 flex items-center justify-center';

  const sliderControl = (isLeft: boolean) => (
    <button
      type="button"
      onClick={isLeft ? previousImage : nextImage}
      className={`${arrowStyle} ${isLeft ? 'left-2' : 'right-2'}`}
      style={{ top: '40%' }}
    >
      <span aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
        {isLeft ?
        <BsFillCaretLeftFill size={30}/>:
        <BsFillCaretRightFill size={30}/>}
      </span>
    </button>
  );

  return (
    <Suspense>
    <div className="p-4 flex justify-center w-screen md:w-2/3 items-center">
      <div className="relative w-full">
        <div className="mx-8 carousel">
          {sliderControl(true)}
          {images.map((img, i) => (
            <div className="w-full flex-shrink-0" key={img} ref={refs[i]}>
              <img src={img} className="" alt="Property view"/>
            </div>
          ))}
          {sliderControl(false)}
        </div>
      </div>
    </div>
    </Suspense>
  );
};
