import HeaderImage from '../../assets/img/HeaderImage.png';
import TopSliderImage from '../../assets/img/TopSliderImage.png';
import { GenericAnimation } from "../common/animations";
import SearchBar from './searchBar';

export function Header() {
  return (
    <>
    <section className="hidden md:flex flex min-h-screen w-screen" style={{ backgroundImage: `url(${TopSliderImage})`, backgroundSize: 'cover' }}>
      <img src={TopSliderImage} style={{"display":"none"}} alt=""/>
      <div className="my-auto mx-12 w-1/3 z-10">
        <GenericAnimation>
          <h1 className="text-4xl md:text-6xl font-bold leading-normal mt-4">Your Favourite Home, Everywhere You Go</h1>
          <p className="text-2xl mt-10 leading-loose font-light">
            Welcome to the future of hospitality and technology. Delivering a new class of accommodation which delivers precisely what the global traveller desires.
          </p>
        </GenericAnimation>
      </div>
    </section>
    <section className="flex md:hidden flex-col w-screen bg-[#f2efeb]">
      <img
        src={HeaderImage}
        className="w-full"
        alt="Property"
        />
      <div className="bg-[#f7f3ef] rounded-t-[30px] -mt-10 mb-2 px-6 py-4">
      <GenericAnimation>
        <h1 className="text-3xl md:text-6xl font-bold leading-normal mt-4">Your Favourite Home, Everywhere You Go</h1>
        <p className="text-lg leading-loose font-light mt-2 mb-4">
          Welcome to the future of Hospitality and Web3: Delivering a new class of accomodation which creates a bridge for real-world revenue on-chain.
        </p>
      </GenericAnimation>
      </div>
    </section>
    <section className="flex flex-row justify-center bg-[#f2efeb]">
		<div className="flex flex-row justify-center bg-transaparent md:-mt-10 mb-10 md:mb-0">
        <SearchBar color='#937b6e' />
      </div>
    </section>
    </>
  )
}