import SwixLogoWhite from '../../assets/img/SwixLogoWhite.png';
import QRCode from '../../assets/img/QRCode.png';
import Orb from '../../assets/img/Orb.png';
import { BOOKING_ENGINE_BASE_URL, PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '../../urls';

export default function Footer() {
  return (
    <footer className="bg-[#2b3238] px-6">
        <div className="md:flex md:justify-between">
            <div className="mb-2 pt-6 flex flex-row md:flex-col justify-around md:justify-start">
                <a href={BOOKING_ENGINE_BASE_URL} className="flex items-center mb-8">
                  <img
                    src={SwixLogoWhite}
                    width={180}
                    height={75}
                    alt="Logo"/>
                </a>
                <a href={BOOKING_ENGINE_BASE_URL}>
                  <img
                    src={QRCode}
                    width={120}
                    height={120}
                    alt="QR Code"/>
                </a>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-2 md:gap-8 md:mt-24">
              <div>
                <h2 className="mb-6 text-2xl font-bold text-gray-900 dark:text-white">Links</h2>
                <ul className="text-gray-200">
                  <li className="mb-4">
                    <a href={PRIVACY_POLICY_URL} className="hover:text-white">Privacy Policy</a>
                  </li>
                  <li className="mb-4">
                    <a href={TERMS_AND_CONDITIONS_URL} className="hover:text-white">Terms of Service</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="hidden md:block">
              <img
                src={Orb}
                width={350}
                height={350}
                className="rotate-90 md:rotate-0"
                alt="Orb"/>
            </div>
        </div>
        <div className="sm:flex sm:items-center sm:justify-between pb-8">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">2022 © Swix. All rights reserved.
            </span>
        </div>
    </footer>
  )
}
