import { Navbar, Footer } from '../layout';

export function Disclaimer() {
  return (
    <>
      <Navbar />
      <div className="px-6 py-6 md:px-20 md:py-10 mb-10">
      <h1 className="text-5xl my-4">GDPR STATEMENT</h1>
      <h2 className="text-3xl my-4">INTRODUCTION</h2>

      <p className="my-2">The new EU General Data Protection Regulation (GDPR) came into effect on 25 May 2018 and was officially recognised and reciprocated by Japan on 17 July 2018. The legislation impacts every organisation which holds or processes personal data. GDPR introduces new responsibilities, including the need to demonstrate compliance, more stringent enforcement and substantially increased penalties than its predecessor the Data Protection Act (DPA).</p>

      <p className="my-2">At SwixDAO, we are committed to the highest standards of information security, privacy and transparency. We place the utmost priority on protecting and handling data in accordance with accepted standards. Our team of experienced Business and Real Estate analysts, advisors and digital specialists are responsible for complying with all applicable GDPR regulations in the capacity of data processors and data controllers, while also working closely with our partners and service providers to meet all contractual obligations of GDPR.</p>

      <p className="my-2">Mono Capital maintains three key areas of strategic focus when approaching GDPR, all of which are implemented and monitored by the Executive Committee:</p>

      <h2 className="text-3xl my-4">1. Compliance</h2>

      <p className="my-2">To consolidate on existing security and business continuity management systems, we will also meet ISO 27001, and ISO 22301 standards, to ensure the highest level of compliance.</p>

      <p className="my-2">Compliance will be supported by a review of any and all existing contracts with data controllers, the use of sub-contractors and any data export arrangements.</p>

      <p className="my-2">The GDPR requires that data controllers define how data processors use the data they get from controllers. These requirements will be embedded in any and all contracts with Partner Service Providers.</p>

      <p className="my-2">Mono Capital’s Executive Committee will inform, advise and monitor compliance. The company will implement tools where necessary which will support the process, providing appropriate security and ongoing delivery of objectives.</p>

      <h2 className="text-3xl my-4">2. Customer Data and Control</h2>

      <p className="my-2">The volume of data processed by organisations is growing and is handled on an increasing number of devices and networks. Requirements such as data protection impact assessments, active mitigation of risks and evidence of risk management measures require a more disciplined approach to customer data, especially those with personal data spread across many locations and/or systems with varying levels of personal data quality and ownership.</p>

      <p className="my-2">SwixDAO is well positioned and confident, that personal and transactional data can be located and anonymised or erased, in order to respond to requests to delete, rectify, transfer, access or restrict the processing of data.</p>

      <p className="my-2">At SwixDAO, we endeavour to provide a simple, but informed experience that will build trust with our Customers.</p>

      <h2 className="text-3xl my-4">3. Internal Processes and Training</h2>

      <p className="my-2">As an organisation that is aligned with GDPR, ensuring updated processes and guidelines for the collection, management and protection of Personally Identifiable Information must be a priority. Employees of every level must also be trained to ensure they understand and have the tools to comply with GDPR standards.</p>
      </div>
      <Footer />
    </>
  )
}
