// import SearchBar from '../../assets/img/SearchBar.png';
import { SplitCard } from '../common/cards';
import SwixGlobeWhiteLogo from '../../assets/img/SwixGlobeWhiteLogo.png';

export function Introduction() {
  return (
    <section className="py-30 md:py-0 md:min-h-screen w-screen bg-[#f2efeb] flex md:flex-row">
      <div className="mx-4 md:w-11/12 xl:w-3/4 md:m-auto flex items-center">
      <SplitCard centerItems={false}>
      <>
        <div className="bg-[#937b6e] rounded-[30px] md:rounded-none md:rounded-l-[60px] md:px-8 md:py-6 mb-4">
          <div className="text-white my-6 mx-8">
            <h2 className="text-4xl md:text-5xl">What is Swix?</h2>
            <p className="font-light mt-4 md:mt-8 md:text-xl md:leading-9">
              Swix is the global short stay rental business which provides refined accommodation services and rewards guests for their loyalty.
            </p>
            <p className="font-light md:text-xl md:leading-9 mt-4 md:mt-8">
              Swix has its own proprietary booking platform and property collective.
            </p>
            <p className="font-light md:text-xl md:leading-9 mt-4 md:mt-8">
              Swix currently has a Prime Central London portfolio of properties welcoming new guests every week and will soon expand across Europe and Asia.
            </p>
          </div>
        </div>
        <div className="bg-[#e1dacc] rounded-[30px] md:rounded-none md:rounded-r-[60px] md:px-8 md:py-6 mb-4">
          <div className="text-black my-6 mx-8">
            <h2 className="text-4xl md:text-5xl">Home is where Swix is</h2>
            <p className="font-light mt-4 md:mt-8 md:text-xl md:leading-9">
              By growing its own collection of properties around the world, Swix is able to consistently deliver exceptional stays in the most desirable locations around the world.
            </p>
            <p className="font-light mt-4 md:mt-8 md:text-xl md:leading-9">
              Every guest receives tech-enabled professional hosting services, coupled with predictable comfort, interiors and eco-efficiency.
            </p>
            <div className="flex justify-end mt-14 hidden md:flex">
              <img
                src={SwixGlobeWhiteLogo}
                width={140}
                height={140}
                alt="Swix Logo"
                />
            </div>
          </div>
        </div>
      </>
      </SplitCard>
      </div>
    </section>
  )
}
