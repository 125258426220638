import { Navbar, Footer } from '../layout';

export function Privacy() {
  return (
    <>
      <Navbar/>
      <div className="px-6 py-6 md:px-20 md:py-10 mb-10">
        <h2 className="text-3xl my-4">1. INTRODUCTION</h2>

        <p className="my-2">SwixDAO respects your privacy. We do not share, rent, sell, or otherwise use any personal information you have given us with any third party without your consent, except when required by law in order to ensure the safety of the public. Any information you give us is held with the highest level of care and security.</p>

        <p className="my-2">Please read our Privacy Policy below. By visiting this website, you are agreeing to the terms of our Privacy Policy.</p>

        <h2 className="text-3xl my-4">2. INFORMATION WE COLLECT</h2>

        <h4 className="text-xl my-4">a. Information You Provide to Us</h4>

        <p className="my-2">We receive and store any information you enter on our website or provide to us in other ways, such as when you fill out a form, participate in a promotion, or make a reservation online. This includes information that can identify you as a natural person (“Personal Information”), including, for example, your first and last names, business, home and postal addresses, e-mail addresses, telephone numbers, gender, travel preferences, and payment information such as credit card number, cardholder name and expiry date.  We will not collect any of your Personal Information except when you have specifically and knowingly given us your permission. You may choose not to provide us with your Personal Information, but some information about you is needed to use our services and/or access certain areas of our websites.</p>

        <h4 className="text-xl my-4">b. Information from Third Parties</h4>

        <p className="my-2">From time to time, we may also obtain both Personal Information and non-personal information about you from affiliated entities, business partners and other independent third-party sources.</p>

        <p className="my-2">We are not liable for the collection, use, disclosure of information or security policies and practices of third parties, such as Facebook, Instagram, Apple, Google, or any other app developer, app provider, social media platform provider, wireless service provider or device manufacturer, with respect to any Personal Information you disclose to third parties through or in connection with our website.</p>

        <h4 className="text-xl my-4">c. Automatic Information</h4>

        <p className="my-2">We automatically collect some information about your computer when you visit this website by using tracking tools such as browser cookies and web beacons, when permitted by law. We will for example collect your IP address.</p>

        <h2 className="text-3xl my-4">3. HOW WE USE YOUR INFORMATION</h2>

        <p className="my-2">We use information as described in this Privacy Policy, subject to any consent required by applicable law.</p>

        <h4 className="text-xl my-4">a. Sensitive Payment Information</h4>

        <p className="my-2">We use sensitive payment information (such as cardholder name, credit card number and expiration date) to complete reservations and leases and/or payment of residential services you carry out on our website.</p>

        <h4 className="text-xl my-4">b. Other Information</h4>

        <p className="my-2">We use other information about you for the following general purposes:</p>

        <p className="my-2">i. in order to tailor and enhance the services we offer you;</p>
        <p className="my-2">ii. to respond to your questions and comments;</p>
        <p className="my-2">iii. to manage your Mono account;</p>
        <p className="my-2">iv. to protect our company, our customers and our website;</p>
        <p className="my-2">v. to notify you of special promotions, products and/or services that may be of interest to you;</p>
        <p className="my-2">vi. to resolve disputes, collect fees or troubleshoot problems;</p>
        <p className="my-2">vii. to enforce our Terms and Conditions of Use;</p>
        <p className="my-2">vii. as otherwise described to you at the point of collection;</p>
        <p className="my-2">ix. for operational business purposes such as data analysis, audits, fraud monitoring and prevention, enhancing, improving or modifying our services, determining the effectiveness of our campaigns, and identifying usage trends.</p>

        <h4 className="text-xl my-4">c. Combined Information</h4>

        <p className="my-2">For example, we may combine information that we have collected offline with information we collect online, to the extent covered by your consent. Or we may combine information we get from a third party with information we already have.</p>

        <h2 className="text-3xl my-4">4. HOW WE SHARE INFORMATION</h2>

        <p className="my-2">We may aggregate and anonymise your information for analytic, research or other business purposes.</p>

        <p className="my-2">We may share non-personal aggregate, or summary, information with partners or other third parties regarding their customers, but we do not sell or share any information at the individual customer level.</p>

        <p className="my-2">We may use and disclose your Personal Information as we believe to be necessary or appropriate: (a) to comply with applicable law (which in certain cases may include laws outside of your country of residence), to respond to requests from public and government authorities (which may also in certain cases include such authorities outside of your country of residence), to cooperate with law enforcement, or for other legal reasons; or (b) to protect our rights, privacy, safety or property, and/or that of our affiliates, you or others.</p>

        <p className="my-2">We may share your Personal Information as part of a sale, merger or change in control or in preparation of any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.</p>

        <h2 className="text-3xl my-4">5. YOUR CHOICES AND OBLIGATIONS</h2>

        <h4 className="text-xl my-4">a. Retention of Information</h4>

        <p className="my-2">We keep your Personal Information as long as it is necessary or relevant for the practices as described in this Privacy Policy. We also keep information as otherwise required by law.</p>

        <h4 className="text-xl my-4">b. Right to Access and Control Your Personal Information</h4>

        <p className="my-2">To Opt-Out.  We provide you with choices regarding our use and disclosure of your Personal Information for marketing purposes.</p>

        <h4 className="text-xl my-4">c. How to Access or Change Your Personal Information</h4>

        <p className="my-2">For those residing in the EU, you have the right to request access to the Personal Information we have on you.  You can do this by contacting us at contact@swixdao.com. For any subsequent access request, we hold the right to charge an administrative fee.</p>

        <p className="my-2">If you would like to review, update, suppress/restrict or delete Personal Information that you have previously provided to us, or if you would like to transfer your Personal Information to another company, you may contact us via email to contact@swixdao.com.</p>

        <p className="my-2">If you believe that the information we have about you is incorrect, you are welcome to contact us so we can update it and keep your information accurate. In order to comply with your request, we may ask you to verify your identity.  We will try to comply with your request as soon as reasonably practicable.</p>

        <p className="my-2">Please note that we may need to retain certain information for record keeping purposes and/or to complete any transactions that you began prior to requesting a change or deletion (e.g., when you have outstanding balance or charges on your account, you may not be able to change or delete the Personal Information provided until after settlement of such financial obligations. There may also be residual information that will remain within our databases and other records which will not be removed.</p>

        <h2 className="text-3xl my-4">6. OTHER IMPORTANT INFORMATION</h2>

        <h4 className="text-xl my-4">a. Security</h4>

        <p className="my-2">We implement security safeguards designed to protect your Personal Information. However, we cannot warrant the security of any Personal Information that you send us. There is no guarantee that such Personal Information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.</p>

        <h4 className="text-xl my-4">b. Cross-Border Data Transfers</h4>

        <p className="my-2">We process data both inside and outside of UK and rely on legally-provided mechanisms to lawfully transfer data across borders when necessary.  If you live outside of UK, you understand and agree that we may transfer your information to UK. UK laws may not afford the same level or protection as those in your own country. In certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in those other countries may be entitled to access your personal information.</p>

        <h4 className="text-xl my-4">c. Lawful Basis for Processing</h4>

        <p className="my-2">We will only collect and process Personal Information about you where we have lawful basis. Lawful basis always includes consent.</p>

        <h4 className="text-xl my-4">d. Standard Security Measures</h4>

        <p className="my-2">The Internet is not completely secure. We cannot promise that your use of our website and app will be completely safe. Any transmission of your Personal Information to our site is at your own risk. We encourage you to use caution when using the Internet. This includes safeguarding your passwords.</p>

        <h4 className="text-xl my-4">e. Use of the Websites by Minors</h4>

        <p className="my-2">This Privacy Policy, along with our website and app, is intended for adults only. We do not knowingly collect personally identifiable information from children under 18 without permission from a parent or legal guardian. If you are a parent or legal guardian and have reason to suspect that your child under 18 has given us information, you can contact us at info@mono-capital.jp, or write to us at the address listed at the end of this Privacy Policy.</p>

        <h4 className="text-xl my-4">f. Contact Us</h4>

        <p className="my-2">If you have any questions about this Privacy Policy, or if you want to correct, update, access or delete, your information with us, please email us at: contact@swixdao.com. For your safety and ours, we may need to verify your identity before fulfilling your request.</p>

        <h4 className="text-xl my-4">g. We May Update This Privacy Policy</h4>

        <p className="my-2">We may periodically change our privacy policies. We will notify you of any material changes to our Privacy Policy as required by law. We will also post an updated copy on our website. Please check our site regularly for updates.</p>
      </div>
      <Footer />
    </>
  )
}
