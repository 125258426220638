import { OutlineCard } from '../common/cards';
import StatsIcon from '../../assets/img/StatsIcon.png';
import { LaptopCarousel } from './carousels';
import { animate } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { GenericAnimation } from "../common/animations";
import { useInView } from "react-intersection-observer";
import { Suspense } from 'react';

function Counter({ from, to, before, after }: { from:any, to:any, before:string, after:string }) {
  const ref = useRef<HTMLParagraphElement>(null);
  const [refView, inView] = useInView();
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    if (inView && !animated) {
    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        ref.current!.textContent = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    });
    setAnimated(true)
    return () => controls.stop();
    }
  }, [from, to, inView]);

  return (
    <div ref={refView}>
      {before}
      <span ref={ref} />
      {after}
    </div>
  );
}

export function Portfolio() {
  return (
    <Suspense>
    <section className="md:min-h-screen w-screen bg-[#f2efeb] flex flex-col justify-center">
      <div className="mx-4 lg:w-3/4 -mt-12 lg:mx-auto">
        <OutlineCard>
          <div className="flex flex-col">
            <div className="my-4 flex flex-row md:hidden justify-center items-center">
              <div className="mr-2">
                <img
                  src={StatsIcon}
                  width={40}
                  height={40}
                  className="relative"
                  alt="Graph Icon"
                  />
              </div>
              <h2 className="text-2xl md:text-4xl">Swix Portfolio Stats</h2>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 mx-2 mb-5 md:mx-24 md:my-24">
              <div className="hidden md:block m-4 w-1/2">
                <h2 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl">Swix Portfolio Stats</h2>
              </div>
              <div className="m-4">
                <h5 className="xl:text-xl">Assets Under Management</h5>
                <h2 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl"><Counter from={37000000} to={37600000} before="$" after=""/></h2>
              </div>
              <div className="m-4">
                <h5 className="xl:text-xl">Occupancy Rate</h5>
                <h2 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl"><Counter from={78} to={88} before="+" after="%"/></h2>
              </div>
              <div className="hidden md:block m-4">
                <img
                  src={StatsIcon}
                  width={80}
                  height={80}
                  className="relative"
                  alt="Graph Icon"
                  />
              </div>
              <div className="m-4">
                <h5 className="xl:text-xl">Headquaters</h5>
                <h2 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl">London UK</h2>
              </div>
              <div className="m-4">
                <h5 className="xl:text-xl">Tenant Satisfaction Rate</h5>
                <h2 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl"><Counter from={87} to={97} before="" after="%"/></h2>
              </div>
            </div>
          </div>
        </OutlineCard>
      </div>
      <div className="flex flex-col items-center mt-24">
        <GenericAnimation>
        <h1 className="text-3xl md:text-5xl text-center mb-4 md:mb-8">Our Portfolio Under Management</h1>
        <h1 className="text-2xl md:text-4xl text-center mb-8 md:mb-16">Growing through demand.</h1>
        <div className="flex justify-center mb-12 md:mb-24">
          <LaptopCarousel/>
        </div>
        </GenericAnimation>
      </div>
    </section>
    </Suspense>
  )
}
