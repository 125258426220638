import { SplitCard } from '../common/cards';
import { OutlineButton } from '../common/buttons';
import SRCIcon from '../../assets/img/SRCIcon.png';
import SRCCHeckGraphic from '../../assets/img/SRCCHeckGraphic.png';
import { Suspense } from 'react';

export function Club() {
  return (
    <Suspense>
    <section className="py-20 md:py-0 md:min-h-screen w-screen border-b border-black bg-[#f5f5f5] flex flex-row">
      <div className="mx-4 md:w-3/4 md:m-auto flex items-center">
        <SplitCard centerItems={false}>
        <>
          <div className="bg-[#e1dacc] rounded-[30px] md:rounded-none md:rounded-l-[60px] md:px-8 md:py-6 md:w-1/2 mb-4">
            <div className="text-black my-6 mx-8">
              <h2 className="text-4xl md:text-5xl">The Swix Residence Club</h2>
              <p className="font-light mt-4 md:mt-8 md:text-xl md:leading-9">
                Every guest is eligible to join at no cost.
              </p>
            </div>
          </div>
          <div className="hidden md:flex flex items-end mt-64 absolute">
            <img
              src={SRCIcon}
              alt="Club Seal"
              className="w-[12vw]"
              />
          </div>
          <div className="bg-[#937b6e] rounded-[30px] md:rounded-none md:rounded-r-[60px] md:px-8 md:py-6 md:w-1/2 mb-4">
            <div className="flex flex-row text-white my-4 mx-4 md:my-2 md:mx-2">
              <h2 className="text-4xl md:text-5xl">Membership Benefits Include</h2>
              <div className="flex justify-end">
                <img
                  src={SRCCHeckGraphic}
                  className="h-100"
                  alt="Club Benefits"
                  />
                </div>
            </div>
          </div>
        </>
        </SplitCard>
      </div>
    </section>
    </Suspense>
  )
}
