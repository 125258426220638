import { SplitCard } from '../common/cards';
import { OutlineButton } from '../common/buttons';
import IpadBkingImage from '../../assets/img/IpadBkingImage.png';
import { Suspense } from 'react';

export function Booking() {
  return (
    <Suspense>
    <section className="py-20 md:py-0 md:min-h-screen w-screen bg-[#e4e2de] flex flex-row">
      <div className="mx-4 md:w-3/4 md:m-auto flex items-center">
        <SplitCard centerItems={true}>
        <>
          <div className="bg-[#937b6e] rounded-[30px] md:rounded-[60px] px-8 py-6 h-1/2 xl:pb-4 2xl:pb-16">
            <div className="text-white my-6 mx-8">
              <h2 className="text-4xl md:text-5xl">The Swix Booking Platform</h2>
              <p className="font-light mt-4 md:mt-8 md:text-xl md:leading-9">
                Accepts payments in Fiat and major Cryptocurrencies, offering a complete solution for all customers.
              </p>
            </div>
          </div>
          <div className="-mt-6 md:mt-0 md:-ml-16">
            <img
              src={IpadBkingImage}
              className="w-100"
              alt="Booking Platform"
              />
          </div>
        </>
        </SplitCard>
      </div>
    </section>
    </Suspense>
  )
}
