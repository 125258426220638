import { GenericAnimation } from "../animations";

export default function SplitCard({ children, centerItems } : { children:any, centerItems:boolean }) {
  return (
    <GenericAnimation>
    <div className={"flex flex-col md:flex-row justify-center" + ( centerItems ? " items-center" : "")}>
      {children}
    </div>
    </GenericAnimation>
  )
}
